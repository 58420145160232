/**
 * @param {string} baseDomain - The base domain of the site (eg: iamsterdam.com)
 * @param {string} url
 * @returns {boolean}
 */
function isExternalUrl(baseDomain, url) {
  if (!baseDomain || !url) {
    return true;
  }
  return (
    (url.startsWith("http://") || url.startsWith("https://")) &&
    !url.includes(baseDomain)
  );
}

export default isExternalUrl;
